// SinglePost.jsx
import {
  Box,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  VStack,
  HStack,
  Input,
  Button,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { db, auth } from '../../initFirebase/initFirebase';
import { onAuthStateChanged } from 'firebase/auth';

const SinglePost = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null); // Store post data
  const [isPostLoading, setIsPostLoading] = useState(true); // Loading state for post
  const [postError, setPostError] = useState(null); // Error state for post

  const [comments, setComments] = useState([]); // Store comments
  const [isCommentsLoading, setIsCommentsLoading] = useState(true); // Loading state for comments
  const [commentsError, setCommentsError] = useState(null); // Error state for comments

  const [commentText, setCommentText] = useState(''); // New comment text
  const [isSubmitting, setIsSubmitting] = useState(false); // Submitting state

  const [currentUser, setCurrentUser] = useState(null); // Current user

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  // Fetch post data
  useEffect(() => {
    const fetchPost = async () => {
      setIsPostLoading(true);
      setPostError(null);

      try {
        const postRef = doc(db, 'posts', postId);
        const postSnap = await getDoc(postRef);

        if (postSnap.exists()) {
          setPost(postSnap.data());
        } else {
          throw new Error('Post not found');
        }
      } catch (err) {
        console.error('Error fetching post:', err);
        setPostError(err.message);
      } finally {
        setIsPostLoading(false);
      }
    };

    fetchPost();
  }, [postId]);

  // Fetch comments data
  useEffect(() => {
    const fetchComments = async () => {
      setIsCommentsLoading(true);
      setCommentsError(null);

      try {
        const commentsRef = doc(db, 'comments', postId);
        const commentsSnap = await getDoc(commentsRef);

        if (commentsSnap.exists()) {
          const data = commentsSnap.data();
          const fetchedComments = Object.entries(data)
            .filter(
              ([key, value]) =>
                key.startsWith('comment_') && value.fieldType === 'comment'
            )
            .map(([key, value]) => ({
              id: key,
              comment: value.comment,
              user: value.user,
              createdAt: value.createdAt,
              edited: value.edited,
            }))
            .sort((a, b) => {
              if (a.createdAt && b.createdAt) {
                return a.createdAt.seconds - b.createdAt.seconds;
              }
              return 0;
            });

          setComments(fetchedComments);
        } else {
          setComments([]); // No comments yet
        }
      } catch (err) {
        console.error('Error fetching comments:', err);
        setCommentsError(err.message);
      } finally {
        setIsCommentsLoading(false);
      }
    };

    fetchComments();
  }, [postId, isSubmitting]); // Refetch comments after submitting a new comment

  // Handle adding a new comment
  const handleAddComment = async (e) => {
    e.preventDefault();
    if (!commentText.trim() || !currentUser) return;

    setIsSubmitting(true);

    try {
      const commentsRef = doc(db, 'comments', postId);

      // Check if the comments document exists
      const commentsSnap = await getDoc(commentsRef);
      if (!commentsSnap.exists()) {
        // If not, create it
        await setDoc(commentsRef, {}); // Initialize with empty object
      }

      // Generate a unique key for the new comment
      const newCommentKey = `comment_${Date.now()}`;

      const newComment = {
        fieldType: 'comment',
        comment: commentText.trim(),
        user: currentUser.email,
        createdAt: serverTimestamp(),
        edited: false,
      };

      // Prepare the update data
      const updateData = {
        [newCommentKey]: newComment,
      };

      await updateDoc(commentsRef, updateData);

      setCommentText('');
      // The useEffect with isSubmitting dependency will refetch comments
    } catch (err) {
      console.error('Error adding comment:', err);
      alert('Failed to add comment. Please try again.'); // You can use a better UI for errors
    } finally {
      setIsSubmitting(false);
    }
  };

  // Utility function to format timestamp
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return date.toLocaleString(); // Customize the format as needed
  };

  const getUsernameFromEmail = (email) => {
    if (!email) return 'Unknown author';
    const parts = email.split('@');
    return parts.length > 1 ? parts[0] : email;
  };

  return (
    <Box px="15px" py="20px" maxW="800px" mx="auto">
      {/* Post Section */}
      {isPostLoading ? (
        <Spinner size="xl" />
      ) : postError ? (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {postError}
        </Alert>
      ) : post ? (
        <VStack align="start" spacing={4} mb={6}>
          <Text fontSize="2xl" fontWeight="bold">
            {post.title}
          </Text>
          <Text fontSize="md">{post.text}</Text>
          {post.createdAt && (
            <Text fontSize="sm" color="gray.500">
              Posted on: {formatTimestamp(post.createdAt)}
            </Text>
          )}
        </VStack>
      ) : (
        <Alert status="warning">
          <AlertIcon />
          Post content is missing.
        </Alert>
      )}

      {/* Comments Section */}
      <Box w="100%">
        <Text fontSize="xl" mb={4}>
          Comments
        </Text>

        {/* Display comments */}
        {isCommentsLoading ? (
          <Spinner size="lg" />
        ) : commentsError ? (
          <Alert status="error" mb={4}>
            <AlertIcon />
            {commentsError}
          </Alert>
        ) : comments.length > 0 ? (
          <VStack align="start" spacing={4} mb={6}>
            {comments.map((comment) => (
              <Box
                key={comment.id}
                w="100%"
                bg="gray.50"
                p={4}
                borderRadius="md"
                boxShadow="sm"
              >
                <HStack justify="space-between" mb={2}>
                  <Text fontWeight="semibold">{getUsernameFromEmail(comment.user)}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {formatTimestamp(comment.createdAt)}
                  </Text>
                </HStack>
                <Text>{comment.comment}</Text>
                {comment.edited && (
                  <Text fontSize="xs" color="gray.400" mt={1}>
                    Edited
                  </Text>
                )}
              </Box>
            ))}
          </VStack>
        ) : (
          <Text>No comments yet. Be the first to comment!</Text>
        )}

        {/* Add Comment Form */}
        {currentUser ? (
          <Box mt={6}>
            <form onSubmit={handleAddComment}>
              <FormControl id="comment" isRequired>
                <FormLabel>Add a Comment</FormLabel>
                <VStack align="start" spacing={3}>
                  <Input
                    type="text"
                    placeholder="Write your comment..."
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                  />
                  <Button
                    type="submit"
                    colorScheme="teal"
                    isLoading={isSubmitting}
                    isDisabled={!commentText.trim()}
                  >
                    Submit
                  </Button>
                </VStack>
              </FormControl>
            </form>
          </Box>
        ) : (
          <Alert status="info" mt={6}>
            <AlertIcon />
            Please log in to add a comment.
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default SinglePost;
