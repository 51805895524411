import { Box, Button, HStack, Select, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text, } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import { MdFontDownload } from "react-icons/md";
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../initFirebase/initFirebase';
import { prompts } from '../../data/propmts';
import { OPENAI_API_KEY } from '../../key';
import { maxAyah } from '../../data/maxAyah';

const AyahAuto = () => {
  const { ayahId } = useParams();
  const navigate = useNavigate(); // Initialize navigate
  const [ayah, setAyah] = useState();
  const [analysis, setAnalysis] = useState();
  const [generatedAnalysis, setGeneratedAnalysis] = useState();
  const [analysisWriteLoading, setAnalysisWriteLoading] = useState(false);

  const [fontSize, setFontSize] = useState(25);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [generating, setIsGenerating] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const [analysisError, setAnalysisError] = useState(null); // Error state
  const [generatingError, setGeneratingError] = useState(null); // Error state
  const [reloadAnalysis, setReloadAnalysis] = useState(false);
  const audioRef = useRef(null);

  const fontChanger = {
    indopak: {
      api: 'https://api.quran.com/api/v4/quran/verses/indopak?verse_key=',
      fontFamily: 'indopak',
      versetext: 'text_indopak'
    },
    uthmani: {
      api: 'https://api.quran.com/api/v4/quran/verses/uthmani?verse_key=',
      fontFamily: 'uthmani',
      versetext: 'text_uthmani'
    },
  };
  const [fontData, setFontData] = useState(fontChanger.indopak);

  useEffect(() => {
    const fetchAyah = async () => {
      setIsLoading(true);
      setError(null);
      setAyah(null);
      try {
        const response = await fetch(
          `${fontData.api}${ayahId}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch sura data');
        }
        const data = await response.json();
        setAyah(data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAyah();
  }, [ayahId, fontData]);

  const handleGenerateAnalysis = async () => {
    setIsGenerating(true);
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: 'chatgpt-4o-latest',
          messages: [
            { role: 'system', content: prompts.ayahBreakdown },
            { role: 'user', content: ayah.verses[0][fontData.versetext] }
          ],
          max_completion_tokens: 10000,
        }),
      });

      const data = await response.json();
      setGeneratedAnalysis([
        { role: 'assistant', content: data.choices[0].message.content },
      ]);
      writeAnalysisToFirebase(data.choices[0].message.content);
      setGeneratingError()
    } catch (error) {
      console.error('Error:', error);
      setGeneratingError('Error:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  // Function to load analysis from firebase collection named 'ayah' using getdoc
  useEffect(() => {
    const fetchAnalysis = async () => {
      setIsLoading(true);
      setAnalysisError(null);
      setAnalysis(null);
      setGeneratedAnalysis(null);
      try {
        const docRef = doc(db, 'ayah', ayahId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setAnalysis(docSnap.data());
          handleNext();
        } else {
          handleGenerateAnalysis();
          throw new Error('No analysis found for this Ayah');
        }
      } catch (error) {
        setAnalysisError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAnalysis();
  }, [ayahId, reloadAnalysis]);

  // write a function to write generated analysis to firebase collection named 'ayah'
  const writeAnalysisToFirebase = async (analysisContent) => {
    try {
      setAnalysisWriteLoading(true);
      const docRef = doc(db, 'ayah', ayahId);
      await setDoc(
        docRef,
        {
          ...ayah.verses[0], // Spread all fetched Ayah data
          analysis: analysisContent, // Add or update the 'analysis' field
          verse_key: ayahId, // Add or update the 'verse_key' field
        },
        { merge: true } // Merge with existing document data
      );
      setAnalysisWriteLoading(false);
      setReloadAnalysis(!reloadAnalysis);
    } catch (error) {
      console.error('Error writing analysis to Firebase:', error);
      setGeneratingError('Failed to save analysis. Please try again.');
    }
  };


  // Function to convert ayahId to six-digit format
  function ayahIdIntoSixDigit(input) {
    const [x, y] = input.split(':');

    const padNumber = (num) => {
      return num.padStart(3, '0');
    };

    const paddedX = padNumber(x);
    const paddedY = padNumber(y);

    return paddedX + paddedY;
  }

  // Function to handle navigation to the previous Ayah
  const handlePrev = () => {
    const [surah, ayah] = ayahId.split(':').map(Number);
    const prevAyah = ayah - 1;
    const newAyahId = `${surah}:${prevAyah}`;
    navigate(`/ayah/${newAyahId}`); // Adjust the path based on your routing
  };

  // Function to handle navigation to the next Ayah
  const handleNext = () => {
    const [surah, ayah] = ayahId.split(':').map(Number);
    if (ayah === maxAyah[surah]) {
      const nextSura = surah + 1;
      const nextAyah = 1;
      const newAyahId = `${nextSura}:${nextAyah}`;
      navigate(`/ayah-auto/${newAyahId}`);
    } else {
      const nextAyah = ayah + 1;
      const newAyahId = `${surah}:${nextAyah}`;
      navigate(`/ayah-auto/${newAyahId}`);
    } 
  };



  if (isLoading) {
    return <div>Loading...</div>; // Loading message
  }

  if (error) {
    return <div>Error: {error.message}</div>; // Error message
  }

  return (
    <Box px='15px'>
      <Box pt='10px' px="10px" align='center'>
        <audio ref={audioRef} controls>
          <source
            src={`https://verses.quran.com/Alafasy/mp3/${ayahIdIntoSixDigit(ayahId)}.mp3`}
            type="audio/mpeg"
          />
          Your browser does not support the audio element.
        </audio>
      </Box>

      {/* Font and Size Controls */}
      <HStack p='10px' mt={4}>
        <Select
          onChange={(e) => setFontData(fontChanger[e.target.value])}
          value={fontData.fontFamily}
        >
          <option value="indopak">Indopak</option>
          <option value="uthmani">Uthmani</option>
        </Select>
        <Slider
          value={fontSize}
          min={15}
          max={50}
          onChange={(value) => setFontSize(value)}
          width="200px"
        >
          <SliderTrack bg='red.100'>
            <SliderFilledTrack bg='tomato' />
          </SliderTrack>
          <SliderThumb boxSize={6}>
            <Box color='tomato' as={MdFontDownload} />
          </SliderThumb>
        </Slider>
        <Text fontSize="sm">Font Size: {fontSize}px</Text>
      </HStack>

      <HStack spacing={4} justify="center" mt={4}>
        <Button onClick={handlePrev} colorScheme="teal">
          Previous
        </Button>
        <Button onClick={handleNext} colorScheme="teal">
          Next
        </Button>
      </HStack>

      {/* Display Ayah Text */}
      {ayah?.verses.map((verse) => (
        <Box key={verse.id} borderBottom="1px" py="5px">
          <Text fontFamily={fontData.fontFamily} fontSize={fontSize} dir="rtl">
            {verse[fontData.versetext]}
            <Text as="span" fontFamily='body' fontSize={fontSize - 10}>
              {' '}({verse.verse_key})
            </Text>
          </Text>
          <Box mt={4}>
            {analysis ?
              <Text fontSize={fontSize} whiteSpace={'pre-wrap'}>{analysis.analysis}</Text>
              : 
              <>
                {analysisError && <Text>{analysisError.message}</Text>}
                <Button onClick={handleGenerateAnalysis} isLoading={generating}>Generate Analysis</Button>
              </>
            }
          </Box>
        </Box>
      ))}

      {generatedAnalysis && (
        <Box mt={4}>
          <Button 
            isLoading={analysisWriteLoading}
            onClick={() => writeAnalysisToFirebase(generatedAnalysis[0]['content'])}
          >
            Upload Analysis to Database
          </Button>
          {generatedAnalysis.map((message, index) => (
            <Text key={index} fontSize={fontSize} whiteSpace={'pre-wrap'}>
              {message.content}
            </Text>
          ))}
        </Box>
      )}

      {generatingError && <Text>{generatingError}</Text>}


      {/* Optional: Repeat Navigation Buttons at the Bottom */}
      <HStack spacing={4} justify="center" mt={4}>
        <Button onClick={handlePrev} colorScheme="teal">
          Previous
        </Button>
        <Button onClick={handleNext} colorScheme="teal">
          Next
        </Button>
      </HStack>
    </Box>
  );
};

export default AyahAuto;
