// CreatePost.jsx
import React, { useContext, useState } from 'react';
import { db } from '../../initFirebase/initFirebase';
import {
  collection,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  Box,
  Input,
  Textarea,
  Button,
  Alert,
  AlertIcon,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { AuthContext } from '../../context/AuthContext';

const CreatePost = () => {
  const [title, setTitle] = useState(''); // New state for the title
  const [postText, setPostText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { currentUser } = useContext(AuthContext);

  // Handle new post submission
  const handlePostSubmit = async (e) => {
    e.preventDefault();

    // Validate title and post text
    if (!title.trim()) {
      setError('Title cannot be empty.');
      return;
    }

    if (!postText.trim()) {
      setError('Post content cannot be empty.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await addDoc(collection(db, 'posts'), {
        title: title.trim(),
        text: postText.trim(),
        author: currentUser.email,
        createdAt: serverTimestamp(),
      });
      setTitle('');
      setPostText('');
    } catch (error) {
      console.error('Error adding document: ', error);
      setError('Failed to add post. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={4} shadow="md" rounded="md" maxW="800px" mx="auto">
      {/* New Post Form */}
      <form onSubmit={handlePostSubmit}>
        {/* Title Input */}
        <FormControl id="title" mb={4} isRequired>
          <FormLabel>Title</FormLabel>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Post Title"
          />
        </FormControl>

        {/* Post Textarea */}
        <FormControl id="post" mb={4} isRequired>
          <FormLabel>Content</FormLabel>
          <Textarea
            value={postText}
            onChange={(e) => setPostText(e.target.value)}
            placeholder="What's on your mind?"
          />
        </FormControl>

        {/* Submit Button */}
        <Button
          type="submit"
          colorScheme="blue"
          isLoading={loading}
          loadingText="Posting"
          disabled={loading}
        >
          Post
        </Button>
      </form>

      {/* Error Alert */}
      {error && (
        <Alert status="error" mt={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default CreatePost;
