import { Box, HStack, Select, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { MdFontDownload } from "react-icons/md";

const Sura = () => {
  const { suraId } = useParams();
  const [sura, setSura] = useState();
  const [fontSize, setFontSize] = useState(25);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state
  const audioRef = useRef(null);

  const fontChanger = {
    indopak : {
      api : 'https://api.quran.com/api/v4/quran/verses/indopak?chapter_number=',
      fontFamily: 'indopak',
      versetext: 'text_indopak'
    },
    uthmani : {
      api : 'https://api.quran.com/api/v4/quran/verses/uthmani?chapter_number=',
      fontFamily: 'uthmani',
      versetext: 'text_uthmani'
    },
  }
  const [fontData, setFontData] = useState(fontChanger.indopak);

  useEffect(() => {
    const fetchSura = async () => {
      setIsLoading(true);
      setError(null);
      setSura(null);
      try {
        const response = await fetch(
          `${fontData.api}${suraId}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch sura data');
        }
        const data = await response.json();
        setSura(data); 
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSura();
  }, [suraId, fontData]);

  if (isLoading) {
    return <div>Loading...</div>; // Display loading message
  }

  if (error) {
    return <div>Error: {error.message}</div>; // Display error message
  }

  return (
    <Box px='15px'>
      <Box pt='10px' px="10px" align='center'>
        <audio ref={audioRef} controls>
          <source
            src={`https://download.quranicaudio.com/qdc/mishari_al_afasy/murattal/${suraId}.mp3`} 
            type="audio/mpeg"
          />
          Your browser does not support the audio element.
        </audio>
      </Box>

      <HStack p='10px'>
        <Select 
          onChange={(e) => setFontData(fontChanger[e.target.value])}
          value={fontData.fontFamily}
        >
          <option value="indopak" >Indopak</option>
          <option value="uthmani" >Uthmani</option>
        </Select>
        <Slider defaultValue={fontSize}  min={15} max={50}
          onChange={(value) => setFontSize(value)}
        >
          <SliderTrack bg='red.100'>
            <SliderFilledTrack bg='tomato' />
          </SliderTrack>
          <SliderThumb boxSize={6}>
            <Box color='tomato' as={MdFontDownload} />
          </SliderThumb>
        </Slider>
      </HStack>
      
      
      {sura.verses.map((verse) => (
        <Box key={verse.id} borderBottom="1px" py="5px">
          <Text fontFamily={fontData.fontFamily} fontSize={fontSize} dir="rtl">
            {verse[fontData.versetext]}
            <Text as="span" fontFamily='body' fontSize={fontSize-10}>
              {' '}(<Link to={`/ayah/${verse.verse_key}`}>{verse.verse_key}</Link>)
            </Text>
          </Text>
        </Box>
      ))}
    </Box>
  );
};

export default Sura;
