// Post.jsx
import React, { useState, useEffect, useContext } from 'react';
import { db } from '../../initFirebase/initFirebase';
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  startAfter,
} from 'firebase/firestore';
import {
  Box,
  Button,
  VStack,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  HStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import CreatePost from './CreatePost';

const Post = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [morePostsAvailable, setMorePostsAvailable] = useState(true);
  const [error, setError] = useState(null);

  const { currentUser } = useContext(AuthContext);

  // Utility function to format timestamp
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'Unknown time';
    // Check if timestamp is a Firestore Timestamp or a JavaScript Date
    const date =
      timestamp.toDate instanceof Function ? timestamp.toDate() : timestamp;
    return date.toLocaleString();
  };

  // Utility function to extract username from email
  const getUsernameFromEmail = (email) => {
    if (!email) return 'Unknown author';
    const parts = email.split('@');
    return parts.length > 1 ? parts[0] : email;
  };

  // Fetch posts from Firestore
  const fetchPosts = async (isLoadMore = false) => {
    setLoading(true);
    setError(null);
    let q;

    try {
      if (isLoadMore && lastVisible) {
        q = query(
          collection(db, 'posts'),
          orderBy('createdAt', 'desc'),
          startAfter(lastVisible),
          limit(20)
        );
      } else {
        q = query(
          collection(db, 'posts'),
          orderBy('createdAt', 'desc'),
          limit(20)
        );
      }

      const querySnapshot = await getDocs(q);
      const fetchedPosts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (isLoadMore) {
        setPosts((prevPosts) => [...prevPosts, ...fetchedPosts]);
      } else {
        setPosts(fetchedPosts);
        console.log(fetchedPosts);
      }

      if (fetchedPosts.length < 20) {
        setMorePostsAvailable(false);
      } else {
        setMorePostsAvailable(true);
      }

      if (querySnapshot.docs.length > 0) {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
      setError('Failed to fetch posts. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load more posts on button click
  const loadMorePosts = () => {
    if (morePostsAvailable) {
      fetchPosts(true);
    }
  };

  return (
    <Box p={4} shadow="md" rounded="md" maxW="800px" mx="auto">
      {currentUser ? (
        <CreatePost />
      ) : (
        <HStack>
          <Text>To create a new post please login.</Text>
          <Link to="/login">
            <Button>Login</Button>
          </Link>
        </HStack>
      )}

      {/* Error Alert */}
      {error && (
        <Alert status="error" mt={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}

      {/* Posts List */}
      <VStack align="stretch" mt={6} spacing={4}>
        {loading && !morePostsAvailable && <Spinner />}

        {posts.map((post) => (
          <Box
            key={post.id}
            p={4}
            borderWidth="1px"
            rounded="md"
            _hover={{ shadow: 'lg' }}
          >
            <Link to={`/post/${post.id}`}>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                {post.text}
              </Text>
              <Text fontSize="lg" color={'gray'} mb={2}>
                Author: {getUsernameFromEmail(post.author)}
              </Text>
              <Text fontSize="lg" mb={2}>
                {post.title}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {post.createdAt
                  ? formatTimestamp(post.createdAt)
                  : 'Just now'}
              </Text>
            </Link>
          </Box>
        ))}

        {/* Load More Button */}
        {morePostsAvailable && !loading && (
          <Button
            onClick={loadMorePosts}
            mt={4}
            colorScheme="teal"
            variant="outline"
            alignSelf="center"
          >
            Load More
          </Button>
        )}

        {/* No More Posts */}
        {!morePostsAvailable && (
          <Text textAlign="center" color="gray.500" mt={4}>
            No more posts to display.
          </Text>
        )}
      </VStack>
    </Box>
  );
};

export default Post;
